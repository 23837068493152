"use client";
import dynamic from "next/dynamic"
import homepageImg from "../images/leasing.webp";
import {Button, Card, CardBody, CardFooter, CardHeader, Input, Typography,   Select,
    Option,
    Checkbox,
    Spinner,
    IconButton} from "@material-tailwind/react";
import Link from "next/link";
import React, {useEffect, useRef, useState} from "react";
import AssetCard from "@/components/AssetCard";
import {apiGetLayouts} from "@/api/asset/GetAssetLayouts";
import {PmStore} from "@/state";
import { apiGetAssetTypes, apiGetLocations, apiMyAssetSearch} from "@/api/asset";
import {useRouter} from "next/navigation";


export default function Home() {
    const state = PmStore();
    let navigate = useRouter();
    const [Assets,SetAssets] = useState([]);
    const [Loading, SetLoading] = useState(false);


    const [Layouts, SetLayouts] = useState([]);
    const [Types, SetTypes] = useState([]);
    const [Locations, SetLocations] = useState([]);

    const [State, SetState] = useState({
        LocationFiltered: false,
        LocationLoading: false,
        Terms: null,
    });


    let SearchTerms = useRef({
        Terms: null,
        LocationId: null,
        LayoutId: null,
        TypeId: null,
    });


    function GetLayouts()
    {
        if(!state?.selectedOrganization?.id) return;
        apiGetLayouts(state?.selectedOrganization?.id).then((data) => SetLayouts(data));
    }
    function GetLocations()
    {
        if(!state?.selectedOrganization?.id) return;
        apiGetLocations(state?.selectedOrganization?.id).then((data) => SetLocations(data));
    }
    function GetAssetTypes()
    {
        if(!state?.selectedOrganization?.id) return;
        apiGetAssetTypes(state?.selectedOrganization?.id).then((data) => SetTypes(data));
    }

    function SearchAssets()
    {
        console.log('search called');
        SetLoading(true);

        if(!state?.selectedOrganization?.id) return;

        let locations = [];
        if(SearchTerms.current.LocationId) locations.push(SearchTerms.current.LocationId);

        let types = [];
        if(SearchTerms.current.TypeId) types.push(SearchTerms.current.TypeId);

        let layouts = [];
        if(SearchTerms.current.LayoutId) types.push(SearchTerms.current.LayoutId);

        apiMyAssetSearch(state?.selectedOrganization?.id, SearchTerms.current.Terms, null, locations, types,  layouts)
            .then((data) => SetAssets(data.items))
            .then(() => SetLoading(false))


    }


    useEffect(() => {
        SearchAssets();
        GetLayouts();
        GetAssetTypes();
        GetLocations();
    }, [state]);


  return (
      <section className=" w-full ">
          <div className="relative h-[30vh] w-full overflow-hidden ">
              <img
                  src={homepageImg.src}
                  alt="background "
                  className="h-full w-full object-cover"
              />
              <div className="absolute top-0 left-0 h-full w-full bg-black/30"/>
          </div>
          <Card className="container mx-auto -mt-[20vh] grid justify-center bg-transparent">
              <CardBody className={"bg-white/95 shadow rounded-lg"}>
                  <Typography className={"text-xl md:text-4xl"}>Welcome to <span
                      className={"text-rf-blue"}>Rental</span> <span
                      className={"text-rf-orange"}>Flow</span></Typography>


                  <form onSubmit={(e) => {
                      e.preventDefault();
                      SearchAssets()
                      navigate.push("#search-results");
                  }}>
                      <div className="relative flex w-full">
                          <Input

                              className="appearance-none !border-t-blue-gray-200 placeholder:text-blue-gray-300 placeholder:opacity-100 focus:!border-t-gray-900 "
                              placeholder={"Search for assets and locations"}
                              onChange={(e) => {
                                  e.preventDefault();
                                  SearchTerms.current.Terms = e.target.value;
                                  SetState((prevState) => ({
                                      ...prevState, Terms: e.target.value
                                  }))
                              }}
                          />
                          <Button size="sm" type={"submit"} className="!absolute right-1 top-1 rounded">Search</Button>
                      </div>
                  </form>
              </CardBody>
          </Card>
          <div className={"mt-[15vh]"}>
              <section className="">
                  <div className="mx-auto text-center mb-16">

                      <Typography variant="h1" className="my-4 text-4xl">
                          Explore Our Availability
                      </Typography>
                      <Typography className="!font-normal text-gray-500 mx-auto max-w-2xl">
                          Keep in mind our availability changes frequently, if you cannot find what you are
                          looking for, please reach out to see what may be coming on the horizon!
                      </Typography>
                  </div>
                  <div className="mx-auto container flex flex-col justify-center px-2 ">
                      <Card shadow={false} className="border border-gray-300 mb-5">
                          <CardHeader
                              shadow={false}
                              floated={false}
                              className="flex items-center justify-between"
                          >
                              <Typography color="blue-gray" variant="h5" className="font-bold">
                                  Refine
                              </Typography>
                              <IconButton variant="text">
                                  {/*<ArrowPathIcon*/}
                                  {/*    className="w-5 h-5 text-gray-900"*/}
                                  {/*    strokeWidth={2}*/}
                                  {/*/>*/}
                              </IconButton>
                          </CardHeader>
                          <CardBody className="grid xl:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-4">
                              <div>
                                  <Select
                                      label="Locations"
                                      animate={{mount: {y: 0}, unmount: {y: 25},}}
                                      containerProps={{className: "min-w-[205px] w-full"}}
                                      disabled={Locations?.length === 0}
                                      onChange={(e) => {
                                          SearchTerms.current.LocationId = e;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              LocationFiltered: true,
                                          }))
                                      }}

                                  >
                                      {Locations?.map((type, index) => (
                                          <Option key={index} value={type.id}>{type.name}</Option>))}
                                  </Select>
                                  <div className={State.LocationFiltered ? "flex justify-center" : "hidden"}>
                                      <Button onClick={() => {
                                          SearchTerms.current.LocationId = null;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              LocationFiltered: false,
                                          }))
                                      }} size={"sm"} className={"m-0 p-1 bg-transparent text-black shadow-none"}>Clear
                                          Location</Button>
                                  </div>

                              </div>

                              <div>
                                  <Select
                                      label="Layouts"
                                      animate={{mount: {y: 0}, unmount: {y: 25},}}
                                      containerProps={{className: "min-w-[205px] w-full"}}
                                      disabled={Locations?.length === 0}
                                      onChange={(e) => {
                                          SearchTerms.current.LayoutId = e;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              LayoutsFiltered: true,
                                          }))
                                      }}

                                  >
                                      {Layouts?.map((type, index) => (
                                          <Option key={index} value={type.id}>{type.name}</Option>))}
                                  </Select>
                                  <div className={State.LayoutsFiltered ? "flex justify-center" : "hidden"}>
                                      <Button onClick={() => {
                                          SearchTerms.current.LayoutId = null;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              LayoutsFiltered: false,
                                          }))
                                      }} size={"sm"} className={"m-0 p-1 bg-transparent text-black shadow-none"}>Clear
                                          Location</Button>
                                  </div>

                              </div>


                              <div>
                                  <Select
                                      label="Type"
                                      animate={{mount: {y: 0}, unmount: {y: 25},}}
                                      containerProps={{className: "min-w-[205px] w-full"}}
                                      disabled={Types?.length === 0}
                                      onChange={(e) => {
                                          SearchTerms.current.TypeId = e;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              TypesFiltered: true,
                                          }))
                                      }}

                                  >
                                      {Types?.map((type, index) => (
                                          <Option key={index} value={type.id}>{type.name}</Option>))}
                                  </Select>
                                  <div className={State.TypesFiltered ? "flex justify-center" : "hidden"}>
                                      <Button onClick={() => {
                                          SearchTerms.current.TypeId = null;
                                          SearchAssets();
                                          SetState((prevState) => ({
                                              ...prevState,
                                              TypesFiltered: false,
                                          }))
                                      }} size={"sm"} className={"m-0 p-1 bg-transparent text-black shadow-none"}>Clear
                                          Location</Button>
                                  </div>

                              </div>


                          </CardBody>

                      </Card>

                      <div id="search-results" >
                      {Loading ? <div className={"flex justify-center"}><Spinner /></div> :
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 lg:grid-cols-3 md:grid-cols-2">
                              {Assets?.slice(0, 6).map((asset, index) => (
                                  <div key={index} className={"h-full "}><AssetCard assetId={asset.id}/></div>
                              ))}


                          </div>
                      }
                      </div>


                  </div>
              </section>
          </div>


      </section>
  );
}

